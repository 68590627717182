
let baseUrl = '/store'
// let baseUrl = '/api'

import axios from "axios";
import { Notify } from "@/utils/notification";
axios.defaults.timeout = 30000; // 设置超时时间

axios.defaults.withCredentials = false;
axios.defaults.headers.post["Content-Type"] =

  "application/x-www-form-urlencoded;charset=utf-8";

// http request 拦截器
axios.interceptors.request.use(
  config => {

    var adminToken = sessionStorage.getItem('adminToken');
    if (adminToken) {
      config.headers.Authorization = 'Bearer ' + adminToken //将token设置到请求头  config.headers.Authorization 
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

const errorCb = error => {
  const errmsg = error.response || error;
  Notify.error({
    message: `Error:${errmsg?.data?.code} ${errmsg?.data?.msg}`
  });
  return Promise.reject(error);
};
const successCb = response => {
  if (
    ![response.status, response.data.code, response.data.status].includes(200)
  ) {
    // http code 为200 但是data.code不是200的情况
    return errorCb(response);
  }
  return Promise.resolve(response);
};

axios.interceptors.request.use(function (config) {
  config.headers["Content-Type"] = "application/json";
  return config;
}, errorCb);

axios.interceptors.response.use(
  // 封装拦截器，出错时弹出错误信息
  successCb,
  errorCb
);

/**
 *
 * @param {string} method  请求接口名，要带"/";
 * @param {*} params  请求接口参数
 * @returns
 */
export const get = (method, params) => {
  return axios
    .get(`${baseUrl}${method}`, {
      params
    })
    .then(res => {
      return Promise.resolve(res.data);
    });
};

/**
 *
 * @param {string} method  请求接口名，要带"/";
 * @param {*} params  请求接口参数
 * @returns
 */
export const post = (method, params) => {
  let data = params;
  if (!params instanceof FormData) {
    data = JSON.stringify(params);
  }
  return axios({
    method: "POST",
    url: `${baseUrl}${method}`,
    data
  }).then(res => {
    return Promise.resolve(res.data);
  });
};

export const put = (method, params) => {
  let data = params;
  if (!params instanceof FormData) {
    data = JSON.stringify(params);
  }
  return axios({
    method: "PUT",
    url: `${baseUrl}${method}`,
    data
  }).then(res => {
    return Promise.resolve(res.data);
  });
};
export const patch = (method, params) => {
  let data = params;
  if (!params instanceof FormData) {
    data = JSON.stringify(params);
  }
  return axios({
    method: "PATCH",
    url: `${baseUrl}${method}`,
    data
  }).then(res => {
    return Promise.resolve(res.data);
  });
};
export const deleteM = (method, params) => {
  let data = params;
  if (!params instanceof FormData) {
    data = JSON.stringify(params);
  }
  return axios({
    method: "DELETE",
    url: `${baseUrl}${method}`,
    data
  }).then(res => {
    return Promise.resolve(res.data);
  });
};

export default axios;
