import Vue from 'vue'
import VueRouter from 'vue-router'
import { shopPermissions } from '@/api/index.js'


// import Home from '../views/Home.vue'
import store from '@/store'
import * as layoutView from './layouts.js'
import * as tiYuView from './tiYu.js'
import * as dongManView from './dongMan.js'
import * as allView from './allView.js'
let layout = Object.values(layoutView)
let tiYuLayout = Object.values(tiYuView)
let allViewLayout = Object.values(allView)
let dongManLayout = Object.values(dongManView)
console.log('allViewLayout', allViewLayout);
Vue.use(VueRouter)
let roleType = sessionStorage.getItem('industry_type')
// console.log('roleType', roleType);
let roleView = roleType == 'figure' ? dongManLayout : tiYuLayout
// console.log('layout', layout);



let layouts = []

// shopPermissions().then(res => {
//   console.log('成功的路由', res);
//   layouts = res.data
// })

// console.log('roleView', roleView);
// layouts = [
//   ...layout,
//   ...roleView
// ]

console.log('layouts', layouts);


export const routes = [
  {
    //地址为空时跳转index页面
    path: '/',
    // redired: '/index',
    redirect: '/homePage/index'
  },
  // {
  //   path: '/',
  //   redirect: '/home'
  // },
  // 登录页面
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue'),
  },
  {
    path: '/home',
    name: 'home',
    isLayout: true,
    component: () => import(/* webpackChunkName: "about" */ '@/views/home/index.vue'),
    children: layouts
  },



]

const router = new VueRouter({
  routes
})

console.log('router', router);
const getPermissions = async () => {
  const { data } = await shopPermissions()
  console.log(data[0].component, 'component829018240830');
  console.log('data[0].name', data[0].name);
  console.log('后端返回的路由', data);
  // require([‘@/components/Demo2’], resolve)
  // allViewLayout.forEach(i => {
  //   router.addRoute(i)
  // })
  data.forEach(item => {
    let { name } = item
    let baseLayout = allViewLayout.find(i => i.name == name)
    item.component = baseLayout?.component


    item?.children.forEach(child => {
      // console.log('编辑父节点', baseLayout?.children;
      child.component = baseLayout?.children.find(i => i.path == child.path)?.component


    })
    router.addRoute(item)
  })
  // 设置左侧录音
  store.commit('setLeftBarRouter', data)
  layouts = data
  // layouts = allViewLayout

}
getPermissions()

router.beforeEach((to, from, next) => {
  // 全局判断登录
  do {
    // if (isDev) break;
    const logined = sessionStorage.getItem('adminToken')
    // let logined = true

    // console.log('s是否登录了:', logined);
    if (!logined) {  //isdev 是开发环境会跳过登录页面 !logined && !isDev
      if (to.name !== "login") {
        next({
          path: "/login"
        });
        return;
      }
      console.log('没有登录 强制去登录页面');

      next();
      return;
    }
    // try {
    //   store.state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // } catch (error) {
    //   Cookies.set("logined", "");
    //   window.location.refresh();
    // }
    if (to.name === "login") {
      next({
        path: "/"
      });
      return;
    }
  } while (false);


  next();


});
export default router
