import { get, post, put, deleteM, patch } from "./request/http";


// export const getCode = () => {
//     return get('/captcha ')
// }

// export const agentAdd = (params) => {
//     return get('/agent_add', params)
// }


// 页面路由

export const shopPermissions = (params) => {
    return get('/shop/permissions', params)
}

// 体育
// 体育商品列表
export const sportGoodList = (params) => {
    return get('/goods/goods_list', params)
}


export const goodsBattleChange = (params) => {
    return post('/goods/battle_change_status', params)
}

export const goodsBattleDel = (params) => {
    return post('/goods/battle_del_goods', params)
}
// 行页分类
export const categoryCategoryFirst = (params) => {
    return post('/category/category_first', params)
}
// 体育商品分类
export const categoryAllChildren = (params) => {
    return post('/category/category_all_children', params)
}

// 体育分类
export const categoryCategoryAllList = (params) => {
    return post('/category/category_all_list', params)
}

export const categoryAdd = (params) => {
    return post('/category/add', params)
}

export const categoryDel = (params) => {
    return post('/category/del', params)
}
export const categoryEdit = (params) => {
    return post('/category/edit', params)
}

// 品类
export const goodsGoodsType = (params) => {
    return post('/goods/goods_type', params)
}

export const goodsGoodsTypeEdit = (params) => {
    return post('/goods/goods_type_add_edit', params)
}

export const goodsGoodsDel = (params) => {
    return post('/goods/del_goods_type', params)
}

// 所有材料
export const goodsGoodsMaterial = (params) => {
    return post('/goods/goods_material', params)
}

export const goodsGoodsMaterialEdit = (params) => {
    return post('/goods/goods_material_add_edit', params)
}

export const goodsGoodsMaterialDel = (params) => {
    return post('/goods/del_goods_material', params)
}
// 所有款式

export const goodsGoodsStyle = (params) => {
    return post('/goods/goods_style', params)
}
export const goodsGoodsStyleEdit = (params) => {
    return post('/goods/goods_style_add_edit', params)
}
export const goodsGoodsStyleDel = (params) => {
    return post('/goods/del_goods_style', params)
}

// 所有版型
export const goodsGoodsPattern = (params) => {
    return post('/goods/goods_pattern', params)
}
export const goodsGoodsPatternEdit = (params) => {
    return post('/goods/goods_pattern_add_edit', params)
}
export const goodsGoodsPatternDel = (params) => {
    return post('/goods/del_goods_pattern', params)
}
// 参数

export const goodsGoodsParam = (params) => {
    return post('/goods/goods_param', params)
}
export const goodsGoodsParamText = (params) => {
    return post('/goods/goods_param_text', params)
}

export const goodsGoodsParamAdd = (params) => {
    return post('/goods/add_param', params)
}
export const goodsGoodsParamTextAdd = (params) => {
    return post('/goods/add_param_text', params)
}
export const goodsGoodsParamTextDel = (params) => {
    return post('/goods/del_param_text', params)
}

export const goodsGoodsParamTextEdit = (params) => {
    return post('/goods/edit_param_text', params)
}

// 动漫
export const apiUploadFilePost = (params) => {
    return post('/api2/upload/file', params)
}
// 上传图片
export const file = (params) => {
    return post('/upload/file', params)
}

export const storeLogin = (params) => {
    return post('/login/login', params)
}

export const shopUserList = (params) => {
    return post('/shop/shop_user_list', params)
}

export const shopUserAdd = (params) => {
    return post('/shop/add_shop_user', params)
}


export const shopUserUpdatePassword = (params) => {
    return post('/shop/update_password', params)
}

export const shopUserDel = (params) => {
    return post('/shop/del_shop_user', params)
}

// 店铺首页

export const shopIndex = (params) => {
    return get('/shop/index', params)
}

// 商品管理
export const goodList = (params) => {
    return get('/anime/goods', params)
}

// 商品上下架
export const animegGoodsEditField = (params) => {
    return post('/anime/goods/edit_field', params)
}
// 表头
export const animeGoodsInit_header = (params) => {
    return get('/anime/goods/init_header', params)
}

// 批量上下架

export const animeGoodsEditStatus = (params) => {
    return put('/anime/goods/edit_status', params)
}




export const animeGoodsEdit = (params) => {
    return post('/anime/goods/edit', params)
}
// 商品详情
export const sportGoodsDetail = (params) => {
    return get('/goods/detail', params)
}
// 商品分类
export const goodsCategory = (params) => {
    return get('/category/category_list', params)
}
// 编辑商品
export const sportGoodsEdit = (params) => {
    return post('/goods/edit', params)
}

// 上架下架
export const changeGoodStatus = (params) => {
    return post('/goods/change_status', params)
}

// 商品详情
export const goodsDetail = (params) => {
    return get('/anime/goods/detail', params)
}


export const goodsDetailDel = (params) => {
    return put('/anime/goods/del', params)
}

// 订单列表
export const orderList = (params) => {
    return post('/order/order_list', params)
}
// 售后订单
export const refundOrderList = (params) => {
    return post('/order/refund_order_list', params)
}
// 日志
export const refundLogList = (params) => {
    return post('/order/refund_log', params)
}
// 订单操作
export const refundOrderAction = (params) => {
    return post('/order/refund_action', params)
}

//物流公司
export const orderlogistic = (params) => {
    return post('/order/logistic', params)
}
//发货
export const sendOrder = (params) => {
    return post('/order/send', params)
}

// 详情
export const orderDetail = (params) => {
    return post('/order/order_detail', params)
}

// 退款配置
export const refundConfigData = (params) => {
    return get('/shop/refund_config', params)
}
export const refundConfigSave = (params) => {
    return post('/shop/refund_config_update', params)
}


// 原著管理

export const originalList = (params) => {
    return get('/anime/original', params)
}

export const originalDel = (params) => {
    return put('/anime/original/del', params)
}

export const originalEdit = (params) => {
    return post('/anime/original/edit', params)
}

// 人物IP管理
export const animeIP = (params) => {
    return get('/anime/ip', params)
}

export const animeIpEdit = (params) => {
    return post('/anime/ip/edit', params)
}

export const animeIpDel = (params) => {
    return put('/anime/ip/del', params)
}

// 分类管理
export const animeCategory = (params) => {
    return get('/anime/category', params)
}

export const animeCategoryEdit = (params) => {
    return post('/anime/category/edit', params)
}


export const animeCategoryDel = (params) => {
    return put('/anime/category/del', params)
}

// 商品标签Label
export const animeGgoodsLabel = (params) => {
    return get('/anime/goods_label', params)
}

export const animeGgoodsLabelEdit = (params) => {
    return post('/anime/goods_label/edit', params)
}


export const animeGgoodsLabelDel = (params) => {
    return put('/anime/goods_label/del', params)
}

// 品牌标签
export const animeBrandLabel = (params) => {
    return get('/anime/brand_label', params)
}


export const animeBrandLabelEdit = (params) => {
    return post('/anime/brand_label/edit', params)
}

export const animeBrandLabelDel = (params) => {
    return put('/anime/brand_label/del', params)
}

// 品牌管理
export const animeBrand = (params) => {
    return get('/anime/brand', params)
}


export const animeBrandEdit = (params) => {
    return post('/anime/brand/edit', params)
}


export const animeBrandDel = (params) => {
    return put('/anime/brand/del', params)
}

// 原创管理
export const animeAchieve = (params) => {
    return get('/anime/achieve', params)
}


export const animeAchieveEdit = (params) => {
    return post('/anime/achieve/edit', params)
}

export const animeAchieveDel = (params) => {
    return put('/anime/achieve/del', params)
}

// 作者管理

export const animeAuthor = (params) => {
    return get('/anime/author', params)
}

export const animeAuthorEdit = (params) => {
    return post('/anime/author/edit', params)
}

export const animeAuthorDel = (params) => {
    return put('/anime/author/del', params)
}

// 材质管理
export const animeMaterial = (params) => {
    return get('/anime/material', params)
}

export const animeMaterialEdit = (params) => {
    return post('/anime/material/edit', params)
}


export const animeMaterialDel = (params) => {
    return put('/anime/material/del', params)
}

// 品牌系列
export const animebrandSeries = (params) => {
    return get('/anime/brand_series', params)
}

export const animebrandSeriesEdit = (params) => {
    return post('/anime/brand_series/edit', params)
}

export const animebrandSeriesDel = (params) => {
    return put('/anime/brand_series/del', params)
}

// 比例管理
export const animeRatio = (params) => {
    return get('/anime/ratio', params)
}

export const animeRatioEdit = (params) => {
    return post('/anime/ratio/edit', params)
}

export const animeRatioDel = (params) => {
    return put('/anime/ratio/del', params)
}

// 摆放位置
export const animePut = (params) => {
    return get('/anime/put', params)
}

export const animePutEdit = (params) => {
    return post('/anime/put/edit', params)
}

export const animePutDel = (params) => {
    return put('/anime/put/del', params)
}

// 专区页面
export const animePage = (params) => {
    return get('/anime/page', params)
}

export const animePageEdit = (params) => {
    return post('/anime/page/edit', params)
}

export const animePageDel = (params) => {
    return put('/anime/page/del', params)
}

// 投放栏目列表

export const animeGoodsColumn = (params) => {
    return get('/anime/goods/column', params)
}

// 广告列表
export const animeAdv = (params) => {
    return get('/anime/adv', params)
}

export const animeAdvEdit = (params) => {
    return post('/anime/adv/edit', params)
}

export const animeAdvDel = (params) => {
    return put('/anime/adv/del', params)
}

// 广告位置
export const animeAdvPosition = (params) => {
    return get('/anime/adv/position', params)
}

// 资讯列表
export const animeArticle = (params) => {
    return get('/anime/article', params)
}


export const animeArticleEdit = (params) => {
    return post('/anime/article/edit', params)
}

export const animeArticleDel = (params) => {
    return put('/anime/article/del', params)
}

// 角色权限


export const permissionsRole = (params) => {
    return get('/permissions/role', params)
}

export const permissionsRoleEdit = (params) => {
    return post('/permissions/role/edit', params)
}

export const permissionsRoleDel = (params) => {
    return put('/permissions/role/del', params)
}

// 权限列表
export const permissionsShopPermissions = (params) => {
    return get('/permissions/shop_permissions', params)
}

export const permissionseditPermissions = (params) => {
    return post('/permissions/edit_permissions', params)
}

// 添加成员
export const shopshopUserList = (params) => {
    return get('/shop/shop_user_list', params)
}

export const shopAddShopUser = (params) => {
    return post('/shop/add_shop_user', params)
}

// 热门问答
export const help = (params) => {
    return get('/help', params)
}

// 热门回答
export const helpEdit = (params) => {
    return post('/help/edit', params)
}

export const helPeditStatus = (params) => {
    return post('/help/edit_status', params)
}

export const helpDel = (params) => {
    return put('/help/del', params)
}

export const helPdelContent = (params) => {
    return put('/help/del_content', params)
}
// 设置回复
export const helpSetContent = (params) => {
    return post('/help/set_content', params)
}

// 订单列表


export const orderOrderList = (params) => {
    return post('/order/order_list', params)
}


export const orderOrderDetail = (params) => {
    return post('/order/order_detail', params)
}

// 活动列表
export const animeColumn = (params) => {
    return get('/anime/column', params)
}

export const animeColumnEdit = (params) => {
    return post('/anime/column/edit', params)
}

export const animeColumnDel = (params) => {
    return put('/anime/column/del', params)
}



// 物流
export const orderLogistic = (params) => {
    return post('/order/logistic', params)
}

export const orderSend = (params) => {
    return post('/order/send', params)
}

// export const orderlogisticInfo = (params) => {
//     return get('/order/logistic_info', params)
// }

export const orderlogisticInfo = (id) => {
    return get(`/order/logistic_info/${id}`)
}


// 导入商品
export const importImportGoods = (params) => {
    return post('/import/import_goods', params)
}

// 售后
export const orderRefundOrderDetail = (params) => {
    return post('/order/refund_order_detail', params)
}


export const refundLogisticInfo = (params) => {
    return post('/order/refund_logistic_info', params)
}

export const updateRefundLogistics = (params) => {
    return post('/order/update_refund_logistics', params)
}