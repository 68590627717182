export const sport = {
    path: '/sport',
    name: 'sport',
    meta: {
        show: true,
        title: '体育商品',
        icon: 'el-icon-goods',
        num: 2

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/shop/index.vue'),
    children: [
        {
            meta: {
                title: '商品管理',
                show: true,
                num: 1
            },
            path: '/sport/goodslist',
            name: 'goodslist',
            component: () => import('@/views/sport/index.vue')
        },
        {
            path: '/sport/addedit',
            name: 'sport/addedit',
            meta: {
                title: '新增/编辑商品',
                show: false,
                num: 2
            },
            component: () => import('@/views/sport/addedit.vue')
        },
        {
            meta: {
                title: '体育分类',
                show: true,
                num: 3
            },
            path: '/sport/sportsClassify',
            name: 'sportsClassify',
            component: () => import('@/views/sport/sportsClassify/index.vue')
        }

    ]
}

