

export const shop = {
    path: '/shop',
    name: 'shop',
    meta: {
        show: true,
        title: '动漫商品',
        icon: 'el-icon-goods',
        num: 2
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/shop/index.vue'),
    children: [
        {
            meta: {
                title: '商品管理',
                show: true
            },
            path: '/shop/commodity',
            name: 'commodity',
            component: () => import('@/views/shop/commodity/index.vue')
        },
        {
            path: '/shop/commodity/addcommodity',
            name: 'commodity/addcommodity',
            meta: {
                title: '新增商品',
                show: false
            },
            component: () => import('@/views/shop/commodity/addcommodity/index.vue')
        },
        {
            meta: {
                title: '原著管理',
                show: true
            },
            path: '/shop/opus',
            name: 'opus',
            component: () => import('@/views/shop/opus/index.vue')
        },
        {
            meta: {
                title: '人物IP管理',
                show: true
            },
            path: '/shop/person',
            name: 'person',
            component: () => import('@/views/shop/person/index.vue')
        },
        {
            meta: {
                title: '分类管理',
                show: true
            },
            path: '/shop/classify',
            name: 'classify',
            component: () => import('@/views/shop/classify/index.vue')
        },
        {
            meta: {
                title: '品牌管理',
                show: true
            },
            path: '/shop/brand',
            name: 'brand',
            component: () => import('@/views/shop/brand/index.vue')
        },
        {
            meta: {
                title: '商品标签',
                show: true
            },
            path: '/shop/commodityLabel',
            name: 'commodityLabel',
            component: () => import('@/views/shop/commodityLabel/index.vue')
        },
        {
            meta: {
                title: '品牌标签',
                show: true
            },
            path: '/shop/brandLabel',
            name: 'brandLabel',
            component: () => import('@/views/shop/brandLabel/index.vue')
        },
        {
            meta: {
                title: '品牌系列管理',
                show: true
            },
            path: '/shop/brandSeries',
            name: 'brandSeries',
            component: () => import('@/views/shop/brandSeries/index.vue')
        },
        {
            meta: {
                title: '原创管理',
                show: true
            },
            path: '/shop/original',
            name: 'original',
            component: () => import('@/views/shop/original/index.vue')
        },
        {
            meta: {
                title: '作者管理',
                show: true
            },
            path: '/shop/author',
            name: 'author',
            component: () => import('@/views/shop/author/index.vue')
        },
        {
            meta: {
                title: '材质管理',
                show: true
            },
            path: '/shop/texture',
            name: 'texture',
            component: () => import('@/views/shop/texture/index.vue')
        },
        {
            meta: {
                title: '比例管理',
                show: true
            },
            path: '/shop/ratio',
            name: 'ratio',
            component: () => import('@/views/shop/ratio/index.vue')
        },
        {
            meta: {
                title: '摆放位置管理',
                show: true
            },
            path: '/shop/locatingPlace',
            name: 'locatingPlace',
            component: () => import('@/views/shop/locatingPlace/index.vue')
        },
    ]
}
