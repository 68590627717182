

import router from '@/router'

export default {
    namespaced: true, //命名空间 也叫名称空间、名字空间
    state: {
        tagsRouter: [],
        active: ''
    },
    getters: {
        // tabs激活
        getTabsActive(state) {
            return state.active
        },
        getTagsRouter(state) {
            //获取标签路由信息
            return state.tagsRouter

        }
    },
    mutations: {

        //   点击新增路由栏
        addRoute(state, routeItem) {
            const { path, name, meta } = routeItem
            console.log('新增', routeItem);
            // 过滤已有的名单
            //选择菜单后，添加至本地路由信息中
            const haveTabs = state.tagsRouter.find(item => item.name == routeItem.name)
            state.active = name
            // console.log('索引', state.active);
            if (!haveTabs) {
                state.tagsRouter.push({
                    path,
                    name,
                    meta,
                })
            }
        },
        //删除tags路由项
        delRoute(state, params) {
            if (!params) return
            const delIndex = state.tagsRouter.findIndex(item => item.name == params)
            state.tagsRouter.splice(delIndex, 1)
            // active
            const before = state.tagsRouter[delIndex - 1]
            const after = state.tagsRouter[delIndex]

            state.active = before?.name || after?.name
        },
        // 设置激活索引
        setActive(state, params) {
            state.active = params
        }
    },
    actions: {
        doSomething(context, val) {
            //应用：this.$store.dispatch("doSomething",{})
            //提交mutations内的方法：context.commit("setUser",val)
        },
    },


}

