// 页面结构
// 主结构

export const homePage = {
    path: '/homePage',
    name: 'homePage',
    meta: {
        show: true,
        title: '首页',
        icon: 'el-icon-s-home',
        num: 2

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/homePage/index',
            name: '/homePage',
            meta: {
                title: '首页',
                show: true
            },
            component: () => import('@/views/homePage/index.vue')
        }
    ]
}


export const Person = {
    path: '/person',
    name: 'person',
    meta: {
        show: true,
        title: '账号',
        icon: 'el-icon-s-custom',
        num: 3

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/person/personList',
            name: '/personpersonList',
            meta: {
                title: '账号管理',
                show: true
            },
            component: () => import('@/views/person/personList/index.vue')
        },
        {
            path: '/person/jurisdiction',
            name: '/person/jurisdiction',
            meta: {
                title: '权限管理',
                show: false
            },
            component: () => import('@/views/person/jurisdiction/index.vue')
        },
    ]
}


export const Order = {
    path: '/order',
    name: 'order',
    meta: {
        show: true,
        title: '订单管理',
        icon: 'el-icon-s-order',
        num: 4

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/order/orderList',
            name: '/orderList',
            meta: {
                title: '订单列表',
                show: true
            },
            component: () => import('@/views/order/orderList.vue')
        },

        {
            path: '/order/orderDetail',
            name: '/orderDetail',
            meta: {
                title: '订单详情',
                show: false
            },
            component: () => import('@/views/order/orderDetail.vue')
        },

        {
            path: '/order/refundOrderList',
            name: '/refundOrderList',
            meta: {
                title: '售后管理',
                show: true
            },
            component: () => import('@/views/order/refundOrderList.vue')
        },
        {
            path: '/order/LogisticsInfo',
            name: '/LogisticsInfo',
            meta: {
                title: '物流信息',
                show: false
            },
            component: () => import('@/views/order/LogisticsInfo.vue')
        },
        {
            path: '/order/refundLogistics',
            name: '/refundLogistics',
            meta: {
                title: '退款物流信息',
                show: false
            },
            component: () => import('@/views/order/refundLogistics.vue')
        },
        {
            path: '/order/afterSale',
            name: '/afterSale',
            meta: {
                title: '售后详情',
                show: false
            },
            component: () => import('@/views/order/afterSale.vue')
        },
    ]
}

export const Config = {
    path: '/config',
    name: 'config',
    meta: {
        show: true,
        title: '店铺管理',
        icon: 'el-icon-s-shop',
        num: 5

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/config/refundConfig',
            name: '/refundConfig',
            meta: {
                title: '退货配置',
                show: true
            },
            component: () => import('@/views/config/refundConfig.vue')
        }
    ]
}

export const operation = {
    path: '/operation',
    name: 'operation',
    meta: {
        show: true,
        title: '运营',
        icon: 'el-icon-s-grid',
        num: 6

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/operation/advertising',
            name: '/advertising',
            meta: {
                title: '广告管理',
                show: true,
                num: 7
            },
            component: () => import('@/views/operation/advertising/index.vue')
        },
        {
            path: '/operation/zonePage',
            name: '/zonePage',
            meta: {
                title: '页面专区管理',
                show: true
            },
            component: () => import('@/views/operation/zonePage/index.vue')
        },
        {
            path: '/operation/activity_List',
            name: '/activity_List',
            meta: {
                title: '活动列表',
                show: true
            },
            component: () => import('@/views/operation/activity_List/index.vue')
        }
    ]
}


export const content = {
    path: '/content',
    name: 'content',
    meta: {
        show: true,
        title: '内容管理',
        icon: 'el-icon-edit-outline',
        num: 7

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/content/information',
            name: '/information',
            meta: {
                title: '资讯列表',
                show: true,
                num: 8
            },
            component: () => import('@/views/content/information/index.vue')
        }
    ]
}




export const pay = {
    path: '/pay',
    name: 'pay',
    meta: {
        show: true,
        title: '财务',
        icon: 'el-icon-money',

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/pay/Info',
            name: '/Info',
            meta: {
                title: '收款信息',
                show: true,

            },
            component: () => import('@/views/pay/Info.vue')
        },
        {
            path: '/pay/ShopAccount',
            name: '/ShopAccount',
            meta: {
                title: '开户信息',
                show: false,
            },
            component: () => import('@/views/pay/ShopAccount.vue')
        }

    ]
}


export const shop = {
    path: '/shop',
    name: 'shop',
    meta: {
        show: true,
        title: '动漫商品',
        icon: 'el-icon-goods',
        num: 2
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/shop/index.vue'),
    children: [
        {
            meta: {
                title: '商品管理',
                show: true
            },
            path: '/shop/commodity',
            name: 'commodity',
            component: () => import('@/views/shop/commodity/index.vue')
        },
        {
            path: '/shop/commodity/addcommodity',
            name: 'commodity/addcommodity',
            meta: {
                title: '新增商品',
                show: false
            },
            component: () => import('@/views/shop/commodity/addcommodity/index.vue')
        },
        {
            meta: {
                title: '原著管理',
                show: true
            },
            path: '/shop/opus',
            name: 'opus',
            component: () => import('@/views/shop/opus/index.vue')
        },
        {
            meta: {
                title: '人物IP管理',
                show: true
            },
            path: '/shop/person',
            name: 'person',
            component: () => import('@/views/shop/person/index.vue')
        },
        {
            meta: {
                title: '分类管理',
                show: true
            },
            path: '/shop/classify',
            name: 'classify',
            component: () => import('@/views/shop/classify/index.vue')
        },
        {
            meta: {
                title: '品牌管理',
                show: true
            },
            path: '/shop/brand',
            name: 'brand',
            component: () => import('@/views/shop/brand/index.vue')
        },
        {
            meta: {
                title: '商品标签',
                show: true
            },
            path: '/shop/commodityLabel',
            name: 'commodityLabel',
            component: () => import('@/views/shop/commodityLabel/index.vue')
        },
        {
            meta: {
                title: '品牌标签',
                show: true
            },
            path: '/shop/brandLabel',
            name: 'brandLabel',
            component: () => import('@/views/shop/brandLabel/index.vue')
        },
        {
            meta: {
                title: '品牌系列管理',
                show: true
            },
            path: '/shop/brandSeries',
            name: 'brandSeries',
            component: () => import('@/views/shop/brandSeries/index.vue')
        },
        {
            meta: {
                title: '原创管理',
                show: true
            },
            path: '/shop/original',
            name: 'original',
            component: () => import('@/views/shop/original/index.vue')
        },
        {
            meta: {
                title: '作者管理',
                show: true
            },
            path: '/shop/author',
            name: 'author',
            component: () => import('@/views/shop/author/index.vue')
        },
        {
            meta: {
                title: '材质管理',
                show: true
            },
            path: '/shop/texture',
            name: 'texture',
            component: () => import('@/views/shop/texture/index.vue')
        },
        {
            meta: {
                title: '比例管理',
                show: true
            },
            path: '/shop/ratio',
            name: 'ratio',
            component: () => import('@/views/shop/ratio/index.vue')
        },
        {
            meta: {
                title: '摆放位置管理',
                show: true
            },
            path: '/shop/locatingPlace',
            name: 'locatingPlace',
            component: () => import('@/views/shop/locatingPlace/index.vue')
        },
    ]
}

export const service = {
    path: '/service',
    name: 'service',
    meta: {
        show: true,
        title: '智能客服',
        icon: 'el-icon-headset',
        num: 10

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/service/issue',
            name: '/issue',
            meta: {
                title: '热门问答',
                show: true,
                num: 8
            },
            component: () => import('@/views/service/issue/index.vue')
        }
    ]
}

export const sport = {
    path: '/sport',
    name: 'sport',
    meta: {
        show: true,
        title: '商品',
        icon: 'el-icon-goods',
        num: 2

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/shop/index.vue'),
    children: [
        {
            meta: {
                title: '商品管理',
                show: true,
                num: 1
            },
            path: '/sport/goodslist',
            name: 'goodslist',
            component: () => import('@/views/sport/index.vue')
        },
        {
            path: '/sport/addedit',
            name: 'sport/addedit',
            meta: {
                title: '新增/编辑商品',
                show: false,
                num: 2
            },
            component: () => import('@/views/sport/addedit.vue')
        },
        {
            meta: {
                title: '商品分类',
                show: true,
                num: 3
            },
            path: '/sport/sportsClassify',
            name: 'sportsClassify',
            component: () => import('@/views/sport/sportsClassify/index.vue')
        },
        {
            meta: {
                title: '品类管理',
                show: true,
                num: 4
            },
            path: '/sport/category',
            name: 'category',
            component: () => import('@/views/sport/category/index.vue')
        },
        {
            meta: {
                title: '版型管理',
                show: true,
                num: 5
            },
            path: '/sport/model',
            name: 'model',
            component: () => import('@/views/sport/model/index.vue')
        },
        {
            meta: {
                title: '材质管理',
                show: true,
                num: 6
            },
            path: '/sport/sportTexture',
            name: 'sportTexture',
            component: () => import('@/views/sport/sportTexture/index.vue')
        },
        {
            meta: {
                title: '款式管理',
                show: true,
                num: 7
            },
            path: '/sport/sportStyle',
            name: 'sportStyle',
            component: () => import('@/views/sport/sportStyle/index.vue')
        },
        {
            meta: {
                title: '参数配置',
                show: true,
                num: 7
            },
            path: '/sport/parameter',
            name: 'parameter',
            component: () => import('@/views/sport/parameter/index.vue')
        }


    ]
}


export const cartoon = {
    path: '/cartoon',
    name: 'cartoon',
    meta: {
        show: true,
        title: '数码商品',
        icon: 'el-icon-goods',
        num: 2

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/shop/index.vue'),
    children: [
        {
            meta: {
                title: '商品管理',
                show: true,
                num: 1
            },
            path: '/sport/goodslist',
            name: 'goodslist',
            component: () => import('@/views/sport/index.vue')
        },
        {
            path: '/sport/addedit',
            name: 'sport/addedit',
            meta: {
                title: '新增/编辑商品',
                show: false,
                num: 2
            },
            component: () => import('@/views/sport/addedit.vue')
        },
        {
            meta: {
                title: '商品分类',
                show: true,
                num: 3
            },
            path: '/sport/sportsClassify',
            name: 'sportsClassify',
            component: () => import('@/views/sport/sportsClassify/index.vue')
        },
        {
            meta: {
                title: '参数配置',
                show: true,
                num: 7
            },
            path: '/sport/parameter',
            name: 'parameter',
            component: () => import('@/views/sport/parameter/index.vue')
        }
    ]
}

