import { get, post, put, deleteM, patch } from "./request/http";


export const leshuaInfo = (params) => {
    return get('/leshua/info', params)
}

export const saveLeshuaData = (params) => {
    return post('/leshua/add', params)
}

export const leshuaMccData = (params) => {
    return get('/leshua/mcc', params)
}


export const leshuaAreaListData = (params) => {
    return get('/leshua/area_list', params)
}


export const leshuaBankList = (params) => {
    return post('/leshua/bank_list', params)
}

export const leshuaBankBranchList = (params) => {
    return post('/leshua/bank_branch_list', params)
}

export const leshuaBankAreaList = (params) => {
    return post('/leshua/bank_area_list', params)
}

export const leshuaRegister = (params) => {
    return get('/leshua/register', params)
}