// 页面结构
// 主结构

export const homePage = {
    path: '/homePage',
    name: 'homePage',
    meta: {
        show: true,
        title: '首页',
        icon: 'el-icon-s-home',
        num: 2

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/homePage/index',
            name: '/homePage',
            meta: {
                title: '首页',
                show: true
            },
            component: () => import('@/views/homePage/index.vue')
        }
    ]
}

export const Person = {
    path: '/person',
    name: 'person',
    meta: {
        show: true,
        title: '人员管理',
        icon: 'el-icon-s-custom',
        num: 3

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/person/personList',
            name: '/personpersonList',
            meta: {
                title: '管理员列表',
                show: true
            },
            component: () => import('@/views/person/personList/index.vue')
        },

        {
            path: '/person/jurisdiction',
            name: '/person/jurisdiction',
            meta: {
                title: '权限管理',
                show: false
            },
            component: () => import('@/views/person/jurisdiction/index.vue')
        },
    ]
}




export const Order = {
    path: '/order',
    name: 'order',
    meta: {
        show: true,
        title: '订单管理',
        icon: 'el-icon-s-order',
        num: 4

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/order/orderList',
            name: '/orderList',
            meta: {
                title: '订单列表',
                show: true
            },
            component: () => import('@/views/order/orderList.vue')
        },

        {
            path: '/order/orderDetail',
            name: '/orderDetail',
            meta: {
                title: '订单详情',
                show: false
            },
            component: () => import('@/views/order/orderDetail.vue')
        },

        {
            path: '/order/refundOrderList',
            name: '/refundOrderList',
            meta: {
                title: '售后订单',
                show: true
            },
            component: () => import('@/views/order/refundOrderList.vue')
        },
    ]
}

export const Config = {
    path: '/config',
    name: 'config',
    meta: {
        show: true,
        title: '店铺管理',
        icon: 'el-icon-s-shop',
        num: 5

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/config/refundConfig',
            name: '/refundConfig',
            meta: {
                title: '退货配置',
                show: true
            },
            component: () => import('@/views/config/refundConfig.vue')
        }
    ]
}

export const operation = {
    path: '/operation',
    name: 'operation',
    meta: {
        show: true,
        title: '运营',
        icon: 'el-icon-s-grid',
        num: 6

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/operation/advertising',
            name: '/advertising',
            meta: {
                title: '广告管理',
                show: true,
                num: 7
            },
            component: () => import('@/views/operation/advertising/index.vue')
        },
        {
            path: '/operation/zonePage',
            name: '/zonePage',
            meta: {
                title: '页面专区管理',
                show: true
            },
            component: () => import('@/views/operation/zonePage/index.vue')
        }
    ]
}


export const content = {
    path: '/content',
    name: 'content',
    meta: {
        show: true,
        title: '内容管理',
        icon: 'el-icon-edit-outline',
        num: 7

    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/person/index.vue'),
    // 子结构
    children: [
        {
            path: '/content/information',
            name: '/information',
            meta: {
                title: '资讯列表',
                show: true,
                num: 8
            },
            component: () => import('@/views/content/information/index.vue')
        }
    ]
}

