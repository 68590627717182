import Vue from 'vue'
import Vuex from 'vuex'
import tabsView from './modules/tabsView'
Vue.use(Vuex)
import { routes, } from "@/router";
// import router from '@/router';
import router from '../router';

export default new Vuex.Store({
  state: {
    isPhone: false,
    navOpen: true,
    leftBarRouter: [],
    userInfo: sessionStorage.getItem("userInfo"),
    adminToken: sessionStorage.getItem('adminToken'),
    shop_name: sessionStorage.getItem('shop_name'),
    newRoute: sessionStorage.getItem('newRoute'),
    industry_type: sessionStorage.getItem('industry_type'),
  },
  getters: {

    sidebarItems(state) {
      // let routesList = routes

      let routesList = state.leftBarRouter
      console.log('routerrouterrouterrouter', state.leftBarRouter);

      // let { children } = routes.find(i => i?.isLayout)

      // if (isDev) return routes;
      // console.log('左侧路由', children);
      routesList = state.leftBarRouter.filter(i => {
        if (!i.meta) return i
        return i.meta.show
      })



      const result = [];
      state.leftBarRouter.forEach(route => {
        if (!route?.meta?.show) return;
        let newRoute = { ...route };
        if (newRoute.children) {
          // 只加入有权限的路由,404等页面会在这一步被筛掉而不显示
          newRoute.children = newRoute.children.filter(
            child => child?.meta?.show
          );
        }
        if (!newRoute.children?.length) return; // 没有子路由的布局不显示

        // 对页面排序

        newRoute.children.sort((a, b) => {
          // const aNum = a.meta.num || 0
          // const bNum = b.meta.num || 0
          const aNum = a.num || 0
          const bNum = b.num || 0
          return aNum - bNum
        })

        result.push(newRoute);
      });
      console.log('左侧过滤路由', result);
      // 对父结构页面排序
      result.sort((a, b) => {
        // const aNum = a.meta?.num || 0
        // const bNum = b.meta?.num || 0
        const aNum = a?.num || 0
        const bNum = b?.num || 0
        return aNum - bNum
      })
      return result;
    }
  },
  mutations: {
    setLeftBarRouter(state, payload) {
      state.leftBarRouter = payload
      console.log('vuex保存的左侧路由', state.leftBarRouter);
    },
    setToken(state, payload) {
      state.adminToken = payload
      // 数据缓存
      sessionStorage.setItem('adminToken', payload)
      // this.$router.push("/");
      setTimeout(() => {
        window.location.reload();

      }, 1800);
    },
    shoolName(state, payload) {
      state.shop_name = payload
      sessionStorage.setItem('shop_name', payload)
      setTimeout(() => {
        window.location.reload();
      }, 1800);
      console.log('payload缓存的名称', payload);
    },
    industryType(state, payload) {
      state.industry_type = payload
      sessionStorage.setItem('industry_type', payload)
      setTimeout(() => {
        window.location.reload();
      }, 1800);

    },
    newField(state, payload) {
      state.newRoute = payload
      sessionStorage.setItem('newRoute', payload)
      console.log(payload);
    },
  },
  modules: {
    tabsView
  },
  actions: {
    logout() {
      sessionStorage.setItem('adminToken', '');

      sessionStorage.removeItem('limit');
      sessionStorage.removeItem('page');

    }
  }
})
