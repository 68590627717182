import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/styles/index.scss"; //scss全局样式文件
import Vuex from 'vuex'

Vue.use(Vuex)

let api = require('./api/index')  //将请求方式挂载全局
let api2 = require('./api/pay')  //将请求方式挂载全局

Vue.prototype.$Api = api
Vue.prototype.$Api2 = api2



import VueCropper from 'vue-cropper'
Vue.use(VueCropper)



import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
import ElementUI from 'element-ui' // element-ui 组件库
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
